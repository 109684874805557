/*
Flaticon icon font: Flaticon
*/
@font-face {
  font-family: "Flaticon";
  src: url("../../assets/fonts/Flaticon.eot");
  src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Flaticon.woff2") format("woff2"),
  url("../../assets/fonts/Flaticon.woff") format("woff"),
  url("../../assets/fonts/Flaticon.ttf") format("truetype"),
  url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
      font-family: "Flaticon";
      src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 15px;
  font-style: normal;
}

.flaticon-piggy-bank:before { content: "\f100"; }
.flaticon-data-encryption:before { content: "\f101"; }
.flaticon-wallet:before { content: "\f102"; }
.flaticon-shield:before { content: "\f103"; }
.flaticon-user:before { content: "\f104"; }
.flaticon-check-mark:before { content: "\f105"; }
.flaticon-settings:before { content: "\f106"; }
.flaticon-envelope-of-white-paper:before { content: "\f107"; }
.flaticon-menu:before { content: "\f108"; }
.flaticon-info:before { content: "\f109"; }
.flaticon-cursor:before { content: "\f10a"; }
.flaticon-alarm:before { content: "\f10b"; }
.flaticon-cancel:before { content: "\f10c"; }
.flaticon-apple:before { content: "\f10d"; }
.flaticon-play-store:before { content: "\f10e"; }
.flaticon-sketch:before { content: "\f10f"; }
.flaticon-web:before { content: "\f130"; }
.flaticon-phone-call:before { content: "\f182"; }